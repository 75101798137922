// Custom Hooks
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getMinutesAndSeconds, calculateTokenValidTime } from "./helpers";
import { useAppContext } from "../AppProvider";

export function useUpdateLogger(message: string, value: any, ) {
  useEffect(() => {
    console.log(message + value);
  }, [value])
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useTokenValidTime() {
  const keycloak = useAppContext().keycloak;
  const [{ minutes, seconds }, setMinutesAndSeconds] = useState<{minutes: string | number, seconds: string | number}>({ minutes: "", seconds: "" });
  useEffect(() => {
    const fetchMinutesAndSeconds = () => {
      (async () => {
        setMinutesAndSeconds(getMinutesAndSeconds(await calculateTokenValidTime(keycloak), true));
      })();
    };

    const timeFetchInterval = setInterval(fetchMinutesAndSeconds, 1000);

    return () => {
      clearInterval(timeFetchInterval);
    };
  }, []);

  return {minutes, seconds}
}

// this is only for development purposes!
export function useAutomaticKeycloakUpdate() {
  const keycloak = useAppContext().keycloak;

  const updateKeycloak = () => {
    (async () => {
      await keycloak.updateToken(300);
    })()
  }

  useEffect(() => {
    const updateTokenInterval = setInterval(updateKeycloak, 180000);

    return () => {
      clearInterval(updateTokenInterval)
    };
  }, []);
}