import { Link } from "react-router-dom";
import { MerchantItem } from "./MerchantItem";

interface DivisionItemProps {
  title: string;
  id: string;
  className?: string;
  path: string;
  merchants: any[];
  currentDivisionID?: string;
  currentMerchantID?: string;
}

export function DivisionItem({
  title,
  id,
  className,
  path,
  merchants,
  currentDivisionID,
  currentMerchantID,
}: DivisionItemProps) {
  const isActive = id === currentDivisionID;
 
  merchants.sort(function(a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; //nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0;  // names must be equal
  });

  return (
    <div className={className + (isActive ? "active " : "") + (isActive ? "expand " : "closed ")}>
      <Link to={path} className="menu-link">
        {title}
        <div className="menu-caret" />
      </Link>
      <div className={"menu-submenu " + (isActive ? "d-block" : "d-none")}>
        {merchants.map((m, index) => (
          <MerchantItem
            currentMerchantID={currentMerchantID}
            title={m.name}
            id={m.slug}
            className="menu-item "
            key={index}
            path={path + "/" + m.slug}
            />
        ))}
      </div>
    </div>
  );
}
