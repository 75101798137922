

export async function calculateTokenValidTime(keycloak: any) {
  const currentTime = new Date().getTime();
  const currentExpireTime = ((await keycloak.getExpireTime()) as Date).getTime();

  if (currentTime < currentExpireTime) {
    return (currentExpireTime - currentTime) / 1000 / 60;
  }
  return 0;
}

export function getMinutesAndSeconds(minutes: number, toString: boolean) {
  const min = minutes | 0;
  const sec = ((minutes - (minutes | 0)) * 60) | 0;

  return { minutes: toString ? min.toString() : min, seconds: toString ? sec >= 10 ? sec.toString() : "0" + sec.toString() : sec};
}