import { Link } from "react-router-dom";

interface MerchantItemProps {
  title: string;
  id: string;
  className?: string;
  path: string;
  currentMerchantID?: string;
}

export function MerchantItem({
  title,
  id,
  className,
  path,
  currentMerchantID,
}: MerchantItemProps) {
  const isActive = id === currentMerchantID;

  return (
    <div className={className + (isActive ? "active " : "")}>
      <Link to={!isActive ? path : path.replaceAll("/" + id, "")} className="menu-link">
        {title}
      </Link>
    </div>
  );
}
