import Tabs, {tabsClasses} from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useLocation, NavLink } from "react-router-dom";
import "./Tabs.css"
import classnames from "classnames";
import {NavItem } from "reactstrap"

interface Props {
  tabs: any[];
  activeTab:string | null;
  }

interface NavTabProps {
  name: string;
  activeTab: string | null;
}

export default function NavbarScrollableTabs({tabs, activeTab}:Props) {
  return (
    <Box sx={{maxWidth: "col-xl-12"}} className="nav nav-tabs nav-tabs-inverse">
      <Tabs 
        value={0}
        variant="scrollable"
        scrollButtons
        aria-label="scrollable auto tabs exambel"
        sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
      >
        {tabs.map((tab) => (
            <NavTab name={tab} key={tab} activeTab={activeTab}/>
        ))}
      </Tabs>
    </Box>
  );
}

function NavTab ({name, activeTab}:NavTabProps){
  const currentPath = useLocation().pathname;
  return (
    <NavItem className="nav-item">
      <NavLink
        activeClassName={classnames({ active: activeTab === name})}
        to={ currentPath + "?folder=" + name}
        className="nav-link"
      >
        {name}
      </NavLink>
    </NavItem>
  );
}