
import React from "react";
import { Link } from "react-router-dom";
import { useTokenValidTime, useAutomaticKeycloakUpdate } from "../../app/hooks";
import "./Header.css"

export function Header() {
  const { minutes, seconds } = useTokenValidTime();

  useAutomaticKeycloakUpdate();

  return (
    <div id="header" className="app-header">
      <div className="navbar-header">
        <Link to="/" className="navbar-brand">
          <b>Abillify Notes</b>
        </Link>
      </div>
      <div className="navbar-nav">
        <div className="Token">
          {minutes === "0" && seconds === "00" ? (
            <h5 style={{ color: "red" }}>Token expired</h5>
          ) : (
            <h5>
              Token expires in {minutes}:{seconds} min
            </h5>
          )}
        </div>
       </div>
    </div>
  );
}