import React, { useContext } from "react";
import { AppSettings } from "./config/app-settings";
import { SocketIO } from "./features/socketio/SocketIO";
import { Keycloak } from "./features/keycloak/Keycloak";


const keycloak = new Keycloak("https://auth.abili.group/auth", "abilimat", "moleculer");
const socket = new SocketIO(window.location.origin.replace(":3000", ":3001"), "/gw", keycloak);

export function useAppContext() {
  return useContext(AppSettings);
}

export function AppProvider({ children }: { children: JSX.Element | null }) {
  return (
    <AppSettings.Provider value={{ socket, keycloak }}>
      <div className="app app-sidebar-fixed app-header-fixed has-scroll">{children}
      </div>

    </AppSettings.Provider>
  );
}
