
import React, { useState, useEffect, useRef } from 'react'
import FileViewer from 'react-file-viewer';
import "./FileDisplay.css"
import { URLPathParams } from "../../type_definitions";
import Iframe from "react-iframe"
import Editor from "rich-markdown-editor"
import { useAppContext } from "../../AppProvider";

interface FileDisplayProps {
  currentFile: any;
  folderPath: any;
  urlPathParams: URLPathParams;
  division: any;
  merchant: any;
}

export default function FileDisplay({currentFile, folderPath, urlPathParams, division, merchant}:FileDisplayProps) {
 
  const fileTypes = useRef("");
  const [driver, setDriver] = useState<JSX.Element>();
  const socket = useAppContext().socket;
  const [viewerVisible, setViewerVisible] = useState(false);
  const [mdVisible, setMdVisible] = useState(false);
  const [markdown1, setMarkdown] = useState("");
  const [path, setPath] = useState("");
  

 useEffect(() => {
  (async()=> {
   
    fileTypes.current = currentFile.split(".");
    if(fileTypes.current[fileTypes.current.length-1] === "md"){
      setMdVisible(true)
      setViewerVisible(false)
    }else{
      setMdVisible(false)
      setViewerVisible(true)
    }
    setPath(await socket.call("react.getPath", {division: division, merchant: urlPathParams.merchID ? merchant.name : null, folder: folderPath, file: currentFile}))
  })();
  },[currentFile]);

  const handleClick = () => {
    fetch(path, {method: "POST", body: markdown1}) ;
  }

  const onError = (e:any) => {
    console.log("error in file viewer " + e);
  };
  
  useEffect(() => {
    console.log("useEffect", fileTypes.current[fileTypes.current.length-1])
    console.log("useEffect", path)
    if(fileTypes.current[fileTypes.current.length-1] === "md"){
      if(!path) {
        return console.log("return " + path);
      }
      fetch(path)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));  
    }else{
      setDriver(getRightViewer(fileTypes.current[fileTypes.current.length-1], path))
    }
  }, [path]);

  //choose Viewer based on file format
  function getRightViewer(fileType:any, filePath:any) {
    console.log("type", fileType)
    console.log("path", filePath)
    if(!filePath){
      console.log("Not Found")
    }else{
      switch(fileType){
      case "pdf": 
        return <FileViewer fileType="pdf" filePath={filePath} onError={onError}/>;
      case "docx":
        return <FileViewer fileType="docx" filePath={filePath} onError={onError}/>;
      case "xlsx": 
      case "csv":  
        return <FileViewer fileType="xlsx" filePath={filePath} onError={onError}/>;  
      default:
        return <></>  
    }}
  }
 
  return(
    <div className="fileDisplay">
      <button className={fileTypes.current[fileTypes.current.length-1] !== "md"? "nonButton" : " buttonViewer open"} onClick={handleClick}>Save</button>
      {viewerVisible && driver}
      {mdVisible && markdown1.length > 0 && <Editor className="mdEditor" defaultValue = {markdown1} onChange={e=>{ setMarkdown(e())}}/>}
    </div>
    /* <Iframe 
      url="https://docs.google.com/document/d/e/2PACX-1vSgap-ccggRDu8uJEO_tK2PREEKLx2vXsMdh5FZf7MUGiuQxUyS-a7-YdaVzYHTgelrvxM_SPwg5erZ/pub?embedded=true"
      height="450px"
      width="450px"
      className="iFrame"
    />*///Google Docs
  )
}


