export class SocketIO {
	socket: any;
	ready: Promise<any>;
	host: string;
	path: string;
	keycloak: any;

	constructor(host: string, path: string, keycloak?: any) {
		this.host = host;
		this.path = path;
		this.keycloak = keycloak;
		this.ready = this.connect();
	}

	async connect() {
		// console.log('construct socketIO', host + path + '/socket.io.js');
		const options: any = { path: this.path };

		if (this.keycloak) {
			const token = await this.keycloak.getToken();
			options.transportOptions = {
				polling: {
					extraHeaders: {
						'Authorization': "Bearer " + token
					}
				},
				websocket: {
					extraHeaders: {
						'Authorization': "Bearer " + token
					}
				}
			};
			this.keycloak.onUpdate(this.keycloakUpdate.bind(this));
		}


		// console.log("connection socketio", options);
		// @ts-ignore
		this.socket = window.io(this.host, options);

		return new Promise((resolve: any, reject: any) => {
			this.socket.on('connect', async () => {
				// @ts-ignore
				window.debug_io = this;
				const r = await this.emit("token", await this.keycloak.getToken());
				// console.log("updated token", r);
				resolve(null);
			});
			this.socket.on('connect_error', (error: any) => {
				if (console) console.log('socket error', error);
				reject(error);
			});
		});
	}

	disconnect() {
		return new Promise((resolve: any) => {
			this.socket.disconnect(() => {
				this.socket = null;
				resolve();
			});
		});
	}

	async keycloakUpdate(e: any, s: boolean) {
		// console.log("got keycloak update", e, s);
		if (!e && s) {
			const r = await this.emit("token", await this.keycloak.getToken());
			// console.log("updated token", r);
		}
	}

	async emit(event: any, data: any) {
		if (event !== "token") {
      await this.ready;
    }
		return new Promise((resolve: any, reject: any) => {
			this.socket.emit(event, data, (response: any) => {
				// console.log("emit response", response);
				// Response is the optional callback that you can use with socket.io in every request. See 1 above.
				if (response?.error) {
					if (console) console.error(response?.error);
					return reject(response.error);
				}
				return resolve(response);
			});
		});
	}

	async on(event: any, func: any) {
		await this.ready;
		return new Promise((resolve: any, reject: any) => {
			this.socket.on(event, func);
			resolve();
		});
	}

	async call(action: string, data?: any) { 
		await this.ready;
		return new Promise((resolve: any, reject: any) => {
			this.socket.emit("call", action, data, (e: any, r: any) => {
				if (e) {
					reject(e);
				} else {
					resolve(r);
				}
			});
		});
	}
}
