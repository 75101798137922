import React from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import { DivisionItem } from "./DivisionItem";

interface SidebarProps {
  currentDivisionID?: string;
  currentMerchantID?: string;
  divisions: any[];
}
  
export function Sidebar({currentDivisionID,currentMerchantID,divisions,}: SidebarProps) {

  divisions.sort(function(a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; //nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0;  // names must be equal
  });
  
  return (
    <React.Fragment>
      <div id="sidebar" className="app-sidebar">
        <PerfectScrollBar className="app-sidebar-content h-100" options={{ suppressScrollX: true }}>
          <div className="menu">
            <div className="menu-header">Divisions</div>
            {divisions.map((div, index) => {
                return(
              <DivisionItem
                title={div.name}
                id={div.slug}
                className="menu-item "
                key={index}
                path={"/div/" + div.slug}
                merchants={Object.values(div.merchants)}
                currentDivisionID={currentDivisionID}
                currentMerchantID={currentMerchantID}
              />
  ) })}
          </div>
        </PerfectScrollBar>
      </div>
    </React.Fragment>
  );
}
  