import React, { useState, useEffect } from 'react'
import "./PopUp.css"

import { useAppContext } from "../../../AppProvider";
import Select from "react-select"
import { URLPathParams } from "../../../type_definitions";

interface PopUpProps {
    closeOption: any;
    options: any;
    urlPathParams: URLPathParams;
    currentDivision: any;
    folder: any
    updateFiles: any
}

export default function PopUp({closeOption, options, urlPathParams, currentDivision, folder, updateFiles}:PopUpProps) {

    const socket = useAppContext().socket;
    const [newName, setnewName] = useState(false);
    const [input, setInput] = useState("");
    const [selectedOption, setSelectedOption] = useState({label:"", value: ""});
   

    console.log("popUp:", folder)

    const handleClose = () => {
        closeOption(false);
        return
    } 

    const handleChange = (e:any) =>{
        setSelectedOption(e)
    }  

    const handleSubmite = () => {
        if(/^\s*$/.test(input)){
            setnewName(false);
            return
        }else{
          selectedOption.label=input ; 
          setnewName(true);
        }
    }
  

    useEffect(() => {
        console.log("popUp useEffect:", folder)
        if(newName === true){

            (async () => {
                await socket.call("react.useTemplate", {template: selectedOption.value, division: currentDivision, merchant: urlPathParams.merchID ? urlPathParams.merchID : null, folder: folder, file: selectedOption.label}) 
                updateFiles(await socket.call("react.getFiles", {division: currentDivision, merchant: urlPathParams.merchID ? urlPathParams.merchID : null, folder: folder}) )
            })();
             handleClose()
        }
    }, [newName]);

    
    let map = options.map((x:any) => (
        {
            label: x,
            value: x
        }));

    return (
        <div className="popUp"> 
            <div className="popUpOuter">
                <div className="popUpInner">
                    <div className="select-container">
                        <div>
                            <Select options={map} onChange={handleChange}/>
                        </div>
                    </div>
                    <div className="input-container">
                        <label>Change Name:</label>
                        <input className="inputNewName" value={input} type="text" onChange={(e) => setInput(e.target.value)}/>
                    </div>
                    <button className="submit-button" onClick={handleSubmite}>Submit</button>
                    <button className="closeButton" onClick={handleClose}><i className="fas fa-times"></i></button>
                </div>
            </div>
        </div>
    )
}
