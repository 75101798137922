import React, {useState, useEffect, useRef} from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { URLPathParams } from "./type_definitions";
import { useAppContext } from "./AppProvider";
import {Sidebar} from "./components/sidebar/Sidebar"
import NavbarScrollableTabs from "./components/navbar/Tabsbar"
import ContentView from './components/contentview/ContentView';
import { MerchantItem } from './components/sidebar/MerchantItem';

interface MainPageProps {
  divisions: any[];
}

export default function MainPage({divisions }: MainPageProps) {
  const urlPathParams = useParams<URLPathParams>();
  const keycloak = useAppContext().keycloak;
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const [tabs, setTabs] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const socket = useAppContext().socket;
  const folderID = query.get("folder");
  const [folders, setFolders]= useState<any[]>([]);
  const division = useRef<any>();
  const merchant = useRef<any>();
  
  useEffect(() => {
    (async () => {
      await keycloak.updateToken(300);
    })();
  });


  useEffect(() => {
    (async () => {
      if (urlPathParams.divID && divisions) {
        division.current = divisions.find(d=>d.slug === urlPathParams.divID );
        
        if(division.current){
         merchant.current = Object.values(division.current.merchants).find((m:any)=>m.slug === urlPathParams.merchID);
      
         setTabs(
          await socket.call("react.getAllFoldersForTabs", {division: division.current.name, merchant: merchant.current ? merchant.current.name : null})
        );
        if(folderID){
        setFolders(
          await socket.call("react.getFolders", {division: division.current.name, merchant: merchant.current ? merchant.current.name : null, folder: folderID ? folderID : null})
        );}else{
          setFolders([])
        }
        setFiles(
          await socket.call("react.getFiles", {division: division.current.name, merchant: merchant.current ? merchant.current.name : null, folder: folderID ? folderID : null})
        );
      }
    }})();
  }, [urlPathParams, folderID]);

  return (
    <div>
      <Sidebar
        currentDivisionID={urlPathParams.divID}
        currentMerchantID={urlPathParams.merchID}
        divisions={divisions}
      />
      <NavbarScrollableTabs tabs={tabs} activeTab={folderID}/>
      <div className="panel-body tab-content">
        <ContentView urlPathParams={urlPathParams} folders={folders} files={files} division={division.current} merchant={merchant.current} folderPath={folderID} query={query} location={location} updateFiles={setFiles}/>
      </div>
    </div>
  )
}

