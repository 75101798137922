export class Keycloak {
	keycloak: any;
    ready: Promise<any>;
    callbacks: any[] = [];

	constructor(url: string, realm: string, clientId: string) {
		// console.log('construct Keycloak', url, realm, clientId);
		// @ts-ignore
        this.keycloak = window.Keycloak({
            url,
            realm,
            clientId
        });
        this.ready = this.init();
	}

    init() {
        return new Promise((resolve,reject) => {
			if (!this.keycloak) return reject('No keycloak connection.');

            this.keycloak
                .init({ onLoad: 'login-required' })
                .then((r?: any)=>{
                    // console.log('keycloak authenticated!', r, "expire", new Date((this.keycloak.tokenParsed).exp*1000), "token", this.keycloak.token);
                    // @ts-ignore
    				window.debug_keycloak = this;
                    resolve(r);
                })
                .catch((e?: any)=>{
                    if (console) console.log('keycloak failed', e);
					reject(e);
                });
		});
    }

    async getToken() {
        await this.ready;
        return this.keycloak.token;
    }

    async getExpireTime() {
        await this.ready;
        return new Date((this.keycloak.tokenParsed).exp*1000);
    }

    async isExpired(seconds?: number) {
        await this.ready;
        return this.keycloak.isTokenExpired(seconds);
    }

    async updateToken(seconds?: number) {
        await this.ready;
        let success: boolean = false;
        let error: any;
        try {
            success = await this.keycloak.updateToken(seconds);
        } catch (e) {
            error = e;
        }
        for (const f of this.callbacks) {
            try {
                f(error, success);
            } catch(e) {
                if(console) console.log("notification callback failed");
            }
        }
        if (error) throw(error);
        return success;
    }

    onUpdate(f: any) {
        this.callbacks.push(f);
    }

}
