import React, { useEffect, useState} from 'react'
import { NavLink } from 'react-router-dom';
import { useAppContext } from "../../AppProvider";
import { URLPathParams } from "../../type_definitions";
import "./ContentView.css";
import FileDisplay from './FileDisplay';
import PopUp from './formularview/PopUp'

interface ContentViewProps {
    urlPathParams: URLPathParams;
    folders: any[];
    files: any[];
    division: any;
    folderPath: any;
    query: any;
    location: any;
    updateFiles: any;
    merchant: any;
}

interface ViewItem {
    folderItems: string[];
    fileItems: FileItem[];
    urlPathParams: URLPathParams;
    division: any;
    folderPath: string;
    count: number;
    activeFile?: any;
    query: any;
    location: any;
    updateFiles: any;
    merchant?: any;
}

type FolderItem = {
    folder: any;
    merchant: any;
    urlPathParams: URLPathParams;
    division: any;
    folderPath: string;
    count: number,
    activeFile: any;
    query: any;
    location: any;
}

type FileItem = {
    file: any;
    activeFile: any;
    query: any;
    location: any;
    urlPathParams: URLPathParams;
    division: any;
    folderPath: any;
}

export default function ContentView({urlPathParams, folders, files, division, folderPath, query, location, updateFiles, merchant}:ContentViewProps) {   
    let count = 0;
    const [file, setFile] = useState("");
    const [currentDivison, setCurrentDivision] = useState(false);
    const [drop, setDrop] = useState(false);
    const currentSubPath = query.get("subfolder");

    useEffect(() => {
        if(!division) {
            setCurrentDivision(false);
        }else{
            setCurrentDivision(true);
        }
    },[division]);

    const activeFile = (currentFile:any) => {
        setFile(currentFile);
        setDrop(true);
    }

    useEffect(() => {
       setDrop(false);
    }, [folderPath, urlPathParams.folderID, urlPathParams.divID, urlPathParams.merchID, currentSubPath]);

    return (
        <div className="tab-pane fade active show">
            {currentDivison && <ViewItem folderItems={folders} fileItems={files} urlPathParams={urlPathParams} division={division} merchant={merchant} folderPath={folderPath} count={count} activeFile={activeFile} query={query} location={location} updateFiles={updateFiles}/>}
            {drop && <FileDisplay currentFile={file} folderPath={folderPath} division={division.name} urlPathParams={urlPathParams} merchant={merchant}/>}
        </div>
    )
}

function ViewItem ({folderItems, fileItems, urlPathParams, division, merchant, folderPath, count, activeFile, query, location, updateFiles}:ViewItem){
    const[options, getOptions] = useState<any[]>([]);
    const [drop, setDrop] = useState(false);
    const socket = useAppContext().socket;

    const handleDrop = () => {
        setDrop(!drop);
    }

    const setClose = (close:any) => {
        setDrop(close);
    }

    useEffect(() => {
        (async()=> {
          getOptions(await socket.call("react.getTemplates"));
        })();
    },[drop]);


    return(
        <>
        <ul className={"listen liste" + count}>
        <button onClick={handleDrop} className="checkFormularButton"><span className="d-flex align-items-center text-start"><i className="fas fa-plus"></i> add Formular</span></button>
        {drop && <PopUp closeOption={setClose} options={options} currentDivision={division.name} urlPathParams={urlPathParams} folder={folderPath} updateFiles={updateFiles}/>}
            {folderItems.map((folder, index) => (
                <FolderItem key={index} folder={folder} urlPathParams={urlPathParams} division={division} merchant={merchant} folderPath={folderPath} count={count} activeFile={activeFile} query={query} location={location}/>
            ))}
            {fileItems.map((file => (
                <FileItem key={file} file={file} activeFile={activeFile} location={location} division={division} urlPathParams={urlPathParams} query={query} folderPath={folderPath}/>
            )))}
        </ul>
        </> 
    )
}

function FolderItem({folder, urlPathParams, division, folderPath, count, activeFile, query, location, merchant}:any) {
    const currentPath = location.pathname;
    const search = location.search;
    const socket = useAppContext().socket;
    const folderID = query.get("folder") as string;
    const currentSubPath = query.get("subfolder") as string;
    const [folders, setFolders]= useState<any[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [visible, setVisible] = useState(false);

    const createUrl = () => {
        if (!search || !folderPath) return "";
        if(folders.length===0 && files.length===0) return currentPath + search.replace("/" + folder, "") ;
        return visible ? (count === 0 ? currentPath + search.slice(0, search.indexOf("&subfolder")) : currentPath + search.replace("/" + folder, "")) : currentPath + "?folder=" + folderID + "&subfolder=" + folderPath.replace(folderID + "/", "") 
    }

    if(!folderPath ){
        folderPath = folderID;
    }else {
        folderPath = folderPath + "/" + folder;
    }

    useEffect(() => {
        const subpathElements = currentSubPath?.split("/");
        
        setVisible(currentSubPath ? ((folderID + "/" + currentSubPath).includes(folderPath) && !subpathElements.every(elem => elem !== folder)) : false);
    })

    useEffect(() => {
        setVisible(false);
    },[folderID]);

    useEffect(() => {
        (async () => {
            setFolders(
                await socket.call("react.getFolders", {division: division.name, merchant: urlPathParams.merchID ? merchant.name : null, folder: folderPath})
              );
              setFiles(
                await socket.call("react.getFiles", {division: division.name, merchant: urlPathParams.merchID ? merchant.name : null, folder: folderPath})
              );
    })();
 },[visible]);

    return(
        <>
        <li className="liste">
            <NavLink 
                to={createUrl()}
                className="folderItem" 
            ><i className={visible ? "fas fa-folder-open" : "fas fa-folder"}></i>
                {folder}
            </NavLink>
        </li>
        {visible && <ViewItem folderItems={folders} fileItems={files} urlPathParams={urlPathParams} division={division} folderPath={folderPath}  count={count=count+1} activeFile={activeFile} query={query} location={location} updateFiles={setFiles}/>}
        </>
    )
}

function FileItem({file, activeFile, location, query, folderPath}:any) {
    const folderID = query.get("folder");
    const currentSubPath = query.get("subfolder");
    const currentPath = location.pathname;

    console.log(folderPath)

    const handleClick = () => {
       activeFile(file);
    }

    const createUrl = () => {
      return currentSubPath ? currentPath + "?" + (folderID ? "folder=" + folderID + "&": "") + "subfolder="+ currentSubPath + "&file=" + file : currentPath + "?" + (folderID ? "folder=" + folderID + "&" : "") + "file=" + file
        //return currentPath + "?" + (folderID ? "folder=" + folderID : "") + ((folderPath && (folderPath.length !== folderID.length)) ? ("&subfolder=" + folderPath.slice(folderPath.indexOf("/" + 1))) : "") + "&file=" + file;
    }

    return(
        <li onClick={handleClick}><i className="fas fa-file"></i>
            <NavLink 
                className="fileItem" 
                to={createUrl()}
            >
                {file}
            </NavLink>
        </li>
    )

}