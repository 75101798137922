import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Header } from "./components/header/Header";
import MainPage from "./MainPage";
import { useAppContext } from "./AppProvider";


export default function App() {
  const [divisions, setDivisions] = useState<any[]>([]);
  const socket = useAppContext().socket;

  useEffect(() => {
    (async () => {
      setDivisions(await socket.call("react.allDivisions", {}));
    })();
  }, []);

  return (
    <div className="app-content">
      <Router>
        <Header/>
        <Switch>
          <Route
            exact
            path="/div/:divID"
            component={() => <MainPage divisions={divisions} />}
          />
          <Route
            exact
            path="/div/:divID/:merchID"
            component={() => <MainPage divisions={divisions} />}
          />
          <Route exact path={"/"} component={() => <MainPage divisions={divisions} />} />
        </Switch>
      </Router>
    </div>
  )
}
